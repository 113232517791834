import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import arrow from '../Img/arrow-icon.svg';
import './banner.css';
import {Link} from "react-router-dom";
import axios from "axios";



function Banner() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    // START: GET BANNERS
		axios.get(global.config.apiUrl+"getBanners")
		.then(res => {
			setBanners(res.data)
		})
		// END: GET BANNERS
  }, []);

  return (
    <div>
      
      <Carousel fade indicators={false}>
      {banners.map((value, index) => {
        return (
          <Carousel.Item>
            <img className="d-block w-100" src={global.config.uploadsPath + value.FILE} alt="Banner 1"  />
            <Carousel.Caption>
              <div>
                <div className="banner-title">
                  <h1>{value.TITLE1}</h1>
                  <p>{value.SUBTITLE1}</p>
                </div>
                <div className='bg-white-transform'></div>
                <div className="banner-caption">
                  <div className="container">
                    <h5>{value.TITLE2}</h5>
                    <p>{value.SUBTITLE2} <a href="#">Ver mais <img className="link-arrow" src={arrow}/></a></p>
                  </div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
        {/*
        <Carousel.Item>
          <img className="d-block w-100" src={Banner2} alt="Banner 2" />
          <Carousel.Caption>
            <div>
              <h1>Ligue-se e <span className='highlight-title'>tenha alma.</span></h1>
              <p>O espaço dedicado aos nossos avôs.</p>
              <Link to="/register" className="btn-white">Registe-se agora</Link>
              <div className='bg-white-transform'></div>
              <div className="banner-caption">
                <h5>Mafra, Lisboa</h5>
                <p>Casa de Repouso São José de Maria <a href="#">Ver mais ></a></p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner3} alt="Banner 3"  />
          <Carousel.Caption>
            <div>
              <h1>Ligue-se e <span className='highlight-title'>tenha alma.</span></h1>
              <p>O espaço dedicado aos nossos avôs.</p>
              <Link to="/register" className="btn-white">Registe-se agora</Link>
              <div className='bg-white-transform'></div>
              <div className="banner-caption">
                <h5>Mafra, Lisboa</h5>
                <p>Casa de Repouso São José de Maria <a href="#">Ver mais ></a></p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        */}
        
      </Carousel>
      
    </div>
  );
}

export default Banner;