import React from 'react';
import ReactDOM from 'react-dom';
import './Header.css';
import logoIcon from '../Img/larin-icon.svg';
import logo from '../Img/logo.svg';
import Login from '../Account/Login';
import Register from '../Account/Register';
import Navbar from 'react-bootstrap/Navbar';
import {
	Link
  } from "react-router-dom";

class Header extends React.Component {
	constructor(props) {
    super(props);
	}
	render () {
		return(
			<div>
				<div className="header hide-desktop">
					<div className='nav-top'>
						<div className="pull-left">
							<img src={logoIcon} className="logo-icon" />
						</div>
						<div className="pull-right">
							<ul className="nav-top-links">
								{localStorage.getItem('userName') != '' && localStorage.getItem('userName') != null &&
									<li><button className="btn-link">{localStorage.getItem('userName')}</button></li>
								}
								{localStorage.getItem('userName') == '' || localStorage.getItem('userName') == null &&
									<li><Link to={"/login"}><button className="btn-link">Iniciar sessão</button></Link></li>
								}
								{localStorage.getItem('userName') == '' || localStorage.getItem('userName') == null &&
									<li><Link to={"/register"}><button className="btn-secondary">Registar-se</button></Link></li>
								}
							</ul>
						</div>
					</div>
				</div>
				<Navbar className="navbar-header show-desktop" id="navbar-top" expand="lg">
					<div className="logo"><Link to="/"><img src={logo} className="header-logo" alt="logo" /></Link></div>
					<ul className="header-navbar">
						<li className="header-navbar-item2"><Link to="/register">Adicionar um lar</Link></li>
						<li className="header-navbar-item"><Link to="#">Ajuda</Link></li>
						<li className="header-navbar-item"><Link to={"/register"}>Registar-se</Link></li>
						<li className="header-navbar-item"><Link to={"/login"}>Iniciar sessão</Link></li>
					</ul>
				</Navbar>
			</div>
		)
	}
}

export default Header;