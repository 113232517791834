import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Bootstrap/bootstrap.min.css';
import logoIcon from './Img/larin-icon.svg';
import Header from './Header/Header';
import Home from './Main/Home';
import Search from './Main/Search';
import Explore from './Main/Explore';
import Messages from './Main/Messages';
import MessagesSingle from './Main/MessagesSingle';
import Menu from './Main/Menu';
import HouseSingle from './Main/HouseSingle';
import BlogSingle from './Main/BlogSingle';
import Login from './Account/Login';
import Register from './Account/Register';
import RegisterAcceptTerms from './Account/RegisterAcceptTerms';
import RegisterSuccess from './Account/RegisterSuccess';
import Blog from './Main/Blog';
import Bottom from './Bottom/Bottom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import ReactGA from "react-ga4";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Routes,
	useParams,
	Link
  } from "react-router-dom";

  const HouseFunc = props => {
	let { spaceID } = useParams(); 
  
	return <HouseSingle activeBottom={1} spaceID={spaceID} history={'home'} {...props}/>
  }
  const HouseServFunc = props => {
	let { serviceID } = useParams(); 
  
	return <HouseSingle activeBottom={4} spaceID={serviceID} history={'home'} {...props}/>
  }

  const ExploreFunc = props => {
	let { districtID, prefixDistrict, nameDistrict, housesTab} = useParams(); 
  
	return <Explore districtID={districtID} prefixDistrict={prefixDistrict} nameDistrict={nameDistrict} housesTab={housesTab}  {...props}/>
  }

  const MessagesSingleFunc = props => {
	let { conversationID, convWith} = useParams(); 
  
	return <MessagesSingle conversationID={conversationID} convWith={convWith} {...props}/>
  }

  const BlogFunc = props => {
	let { blogID } = useParams(); 
  
	return <BlogSingle blogID={blogID} {...props}/>
  }
  var that = this;
  const initApp = (myDistrict) => {
	ReactGA.initialize("G-QNNE05MY2P");
	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
		<div id="main-content">
			<Router>
				<Routes>
					<Route exact path="/" element={
						<div>
							<Header />
							<Home myDistrict={myDistrict} />
							<Bottom key={0} activeIndexRouter={"home"} />
						</div>
					} />
					<Route exact path="/larin" element={
						<div>
							<Header />
							<Home myDistrict={myDistrict} />
							<Bottom key={0} activeIndexRouter={"home"}/>
						</div>
					} />
					<Route exact path="/search" element={
						<div>
							<Search />
							<Bottom key={1} activeIndexRouter={"search"}/>
						</div>
					} />
					<Route exact path="/explore" element={
						<div>
							<Explore districtID={localStorage.getItem('myDistrictID')} prefixDistrict={localStorage.getItem('myDistrictPrefix')} nameDistrict={localStorage.getItem('myDistrict')} housesTab={0} />
							<Bottom key={2} activeIndexRouter={"explore"}/>
						</div>
					} />
					<Route exact path="/explore/:districtID/:prefixDistrict/:nameDistrict/:housesTab" element={
						<div>
							<ExploreFunc />
							<Bottom key={3} activeIndexRouter={"explore"}/>
						</div>
					} />
					<Route exact path="/services" element={
						<div>
							<Explore districtID={localStorage.getItem('myDistrictID')} prefixDistrict={localStorage.getItem('myDistrictPrefix')} nameDistrict={localStorage.getItem('myDistrict')} housesTab={1} />
							<Bottom key={4} activeIndexRouter={"explore"}/>
						</div>
					} />
					<Route exact path="/events" element={
						<div>
							<Explore districtID={localStorage.getItem('myDistrictID')} prefixDistrict={localStorage.getItem('myDistrictPrefix')} nameDistrict={localStorage.getItem('myDistrict')} housesTab={2} />
							<Bottom activeIndexRouter={"explore"}/>
						</div>
					} />
					<Route exact path="/messages" element={
						<div>
							<Messages />
							<Bottom key={5} activeIndexRouter={"messages"}/>
						</div>
					} />
					<Route exact path="/message/:conversationID/:convWith" element={
						<div>
							<MessagesSingleFunc />
						</div>
					} />
					<Route exact path="/menu" element={
						<div>
							<Menu />
							<Bottom key={6} activeIndexRouter={"menu"}/>
						</div>
					} />
					<Route exact path="/house/:spaceID" element={
						<div>
							<HouseFunc />
						</div>
					} />
					<Route exact path="/service/:serviceID" element={
						<div>
							<HouseServFunc />
						</div>
					} />
					
					<Route exact path="/login" element={
						<div>
							<Login />
							<Bottom key={7} activeIndexRouter={""}/>
						</div>
					} />
					<Route exact path="/register" element={
						<div>
							<Register />
							<Bottom key={8} activeIndexRouter={""}/>
						</div>
					} />
					<Route exact path="/register-accept" element={
						<div>
							<RegisterAcceptTerms />
						</div>
					} />
					<Route exact path="/register-success" element={
						<div>
							<RegisterSuccess />
						</div>
					} />
					<Route exact path="/revista" element={
						<div>
							<Blog />
							<Bottom key={10} activeIndexRouter={""}/>
						</div>
					} />
					<Route exact path="/revistasingle/:blogID/:blogTitle" element={
						<div>
							<BlogFunc />
						</div>
					} />
				</Routes>
			</Router>
		</div>
	);
  }

  if ("geolocation" in navigator) {
	
	navigator.geolocation.getCurrentPosition(function(position) {
	console.log("Latitude is :", position.coords.latitude);
	console.log("Longitude is :", position.coords.longitude);
	localStorage.setItem('myLatitude', position.coords.latitude);
	localStorage.setItem('myLongitude', position.coords.longitude);
	// START: GET DISTRICT BY COORDINATES
	axios.get("https://geoapi.pt/gps/"+position.coords.latitude+","+position.coords.longitude).then(function(response){
		//alert(response.erro);
		const myDistrict = response.data.distrito;
		localStorage.setItem('myDistrict', response.data.distrito);
		axios.get(global.config.apiUrl+"getDistrictByName/"+myDistrict)
		.then(res => {
			const district = res.data;
			localStorage.setItem('myDistrictID', district.DISTRICT_ID);
			localStorage.setItem('myDistrictPrefix', district.PREFIX);
			initApp(myDistrict);
		})
	}.bind(that)).catch(function (error) {
		localStorage.setItem('myDistrict', 0);
		localStorage.setItem('myDistrictID', 0);
		localStorage.setItem('myDistrictPrefix', 0);
		initApp(0);
	});
	
		
	},
	function(){
		localStorage.setItem('myDistrict', 0);
		localStorage.setItem('myDistrictID', 0);
		localStorage.setItem('myDistrictPrefix', 0);
		initApp(0);
	})
	
} else {
	const myDistrict = 0;
	localStorage.setItem('myDistrict', 0);
	localStorage.setItem('myDistrictID', 0);
	localStorage.setItem('myDistrictPrefix', 0);
	initApp();
}
  	

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

