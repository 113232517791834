import React from 'react';
import ReactDOM from 'react-dom';
import './Header.css';
import logoIcon from '../Img/larin-icon.svg';
import logo from '../Img/logo-color.svg';
import Login from '../Account/Login';
import Register from '../Account/Register';
import Navbar from 'react-bootstrap/Navbar';
import {
	Link
  } from "react-router-dom";

class Header2 extends React.Component {
	constructor(props) {
    super(props);
	}
	render () {
		return(
			<div>
				<Navbar className="navbar-header2 show-desktop" id="navbar-top2" expand="lg">
					<div className="logo"><Link to="/"><img src={logo} className="header-logo" alt="logo" /></Link></div>
					<ul className="header-navbar">
						<li className="header-navbar-item2"><Link to="/register">Adicionar um lar</Link></li>
						<li className="header-navbar-item"><Link to="#">Ajuda</Link></li>
						<li className="header-navbar-item"><Link to={"/register"}>Registar-se</Link></li>
						<li className="header-navbar-item"><Link to={"/login"}>Iniciar sessão</Link></li>
					</ul>
				</Navbar>
			</div>
		)
	}
}

export default Header2;